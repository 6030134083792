<header class="header bg-dark py-2 d-flex align-items-stretch border-bottom border-dark">
    <div class="container">
        <div class="row">
            <div class="col-md col-sm my-auto">
                <img class="img-fluid" src="/assets/img/logo_blanco.png" width="300px">
            </div>
            <div class="col-md col-sm my-auto" *ngIf="company == 'Aon' || company == 'Grupo Parker' || partner == 'Aon'" style="text-align: center;">
                <img class="img-fluid mt-2" src="/assets/img/aon_logo.png" width="150px">
            </div>
            <div class="col-md col-sm my-auto" *ngIf="company == 'Grupo Parker'">
                <img class="img-fluid" src="/assets/img/logo-parker.png">
            </div>
            <div class="col-md"></div>
            <div class="col-md-1 col-sm-12 text-end my-auto text-white">
                <fa-icon [icon]="faGlobe" class="fa-2x"></fa-icon>
            </div>
            <div class="col-md-2 col-sm-12 text-center my-auto px-0">
                <select class="form-select form-select-sm"
                        aria-label="form-select-sm example"
                        id="lang"
                        name="lang"
                        [(ngModel)]="lang"
                        autocomplete="off"
                        required
                        *ngIf="lang == 'ES'"
                        (change)="setLanguage(lang);">
                        <option [ngValue]="i.id" *ngFor="let i of language_es">{{i.text}}</option>
                </select>
                <select class="form-select form-select-sm"
                        aria-label="form-select-sm example"
                        id="lang"
                        name="lang"
                        [(ngModel)]="lang"
                        autocomplete="off"
                        required
                        *ngIf="lang == 'PT'"
                        (change)="setLanguage(lang);">
                        <option [ngValue]="i.id" *ngFor="let i of language_pt">{{i.text}}</option>
                </select>
                <select class="form-select form-select-sm"
                        aria-label="form-select-sm example"
                        id="lang"
                        name="lang"
                        [(ngModel)]="lang"
                        autocomplete="off"
                        required
                        *ngIf="lang == 'EN'"
                        (change)="setLanguage(lang);">
                        <option [ngValue]="i.id" *ngFor="let i of language_en">{{i.text}}</option>
                </select>
            </div>
        </div>
    </div>
</header>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-10 mx-auto mt-2">
            <div class="row">
                <div class="col-md-6" style="align-self: center;">
                    <h2 class="text-center fc-secondary" *ngIf="lang == 'ES'">¡Descubre tu calificación de Bienestar Financiero!</h2>
                    <h2 class="text-center fc-secondary" *ngIf="lang == 'PT'">Descubra sua pontuação de Bem-estar financeiro!</h2>
                    <h2 class="text-center fc-secondary" *ngIf="lang == 'EN'">Discover your Financial Wellness score!</h2>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <form [formGroup]="formPersonalData" novalidate>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="coreo" *ngIf="lang == 'ES'">Correo*</label>
                                <label class="my-2 fc-secondary" for="coreo" *ngIf="lang == 'PT'">Correio eletrônico*</label>
                                <label class="my-2 fc-secondary" for="coreo" *ngIf="lang == 'EN'">Email*</label>
                                <input type="email"
                                    class="form-control form-control-lg"
                                    id="coreo"
                                    name="coreo"
                                    formControlName="mail"
                                    autocomplete="off"
                                    placeholder="ejemplo@email.com"
                                    required
                                    autofocus="autofocus">
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="nacimiento" *ngIf="lang == 'ES'">Fecha de nacimiento*</label>
                                <label class="my-2 fc-secondary" for="nacimiento" *ngIf="lang == 'PT'">Data de nascimento*</label>
                                <label class="my-2 fc-secondary" for="nacimiento" *ngIf="lang == 'EN'">Date of birth*</label>
                                <input type="text"
                                    class="form-control form-control-lg"
                                    id="nacimiento"
                                    name="nacimiento"
                                    formControlName="nacimiento"
                                    autocomplete="off"
                                    placeholder="DD/MM/AAAA"
                                    mask="d0/M0/0000"
                                    [dropSpecialCharacters]="false"
                                    required>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="countryCode" *ngIf="lang == 'ES'">País*</label>
                                <label class="my-2 fc-secondary" for="countryCode" *ngIf="lang == 'PT'">País*</label>
                                <label class="my-2 fc-secondary" for="countryCode" *ngIf="lang == 'EN'">Country*</label>
                                <select class="form-select form-control-lg"
                                        aria-label="form-select-sm example"
                                        id="countryCode"
                                        name="countryCode"
                                        formControlName="countryCode"
                                        autocomplete="off"
                                        required
                                        *ngIf="lang != 'EN'">
                                        <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                        <option [ngValue]="i.code" *ngFor="let i of countries">{{i.nameEs}}</option>
                                </select>
                                <select class="form-select form-control-lg"
                                        aria-label="form-select-sm example"
                                        id="countryCode"
                                        name="countryCode"
                                        formControlName="countryCode"
                                        autocomplete="off"
                                        required
                                        *ngIf="lang == 'EN'">
                                        <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                        <option [ngValue]="i.code" *ngFor="let i of countries">{{i.nameEn}}</option>
                                </select>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="sexo" *ngIf="lang == 'ES'">Sexo*</label>
                                <label class="my-2 fc-secondary" for="sexo" *ngIf="lang == 'PT'">Gênero*</label>
                                <label class="my-2 fc-secondary" for="sexo" *ngIf="lang == 'EN'">Gender*</label>
                                <select class="form-select form-select-lg"
                                        aria-label="form-select-sm example"
                                        id="sexo"
                                        name="sexo"
                                        formControlName="sexo"
                                        autocomplete="off"
                                        required>
                                        <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                        <option [ngValue]="i" *ngFor="let i of inputOptionsGenero">{{i}}</option>
                                </select>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2" *ngIf="(company == 'Alfa Corporativo' && group == 'Estudio FW') || company == 'Ingram' || company == 'Grupo Parker' || company == 'Natura'">
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'ES' && company == 'Grupo Parker'">Planta*</label>
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'PT' && company == 'Grupo Parker'">Estação de trabalho*</label>
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'EN' && company == 'Grupo Parker'">Station*</label>
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'ES' && company != 'Grupo Parker'">Filial*</label>
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'PT' && company != 'Grupo Parker'">Empresa afiliada*</label>
                                <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'EN' && company != 'Grupo Parker'">Affiliated company*</label>
                                <select class="form-select form-select-lg"
                                        aria-label="form-select-sm example"
                                        id="filial"
                                        name="filial"
                                        formControlName="filial"
                                        autocomplete="off"
                                        required>
                                        <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                        <option [ngValue]="i" *ngFor="let i of inputOptionsFilial">{{i}}</option>
                                </select>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2" *ngIf="company == 'Heineken'">
                              <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'ES' ">Tipo de contrato*</label>
                              <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'PT'">Tipo de contratoTipo de contrato*</label>
                              <label class="my-2 fc-secondary" for="filial" *ngIf="lang == 'EN' ">Tipo de contrato*</label>
                              <select class="form-select form-select-lg"
                                      aria-label="form-select-sm example"
                                      id="filial"
                                      name="filial"
                                      formControlName="filial"
                                      autocomplete="off"
                                      required>
                                <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                <option [ngValue]="i" *ngFor="let i of inputOptionsFilial">{{i}}</option>
                              </select>
                              <!-- <div class="invalid-feedback"
                                  ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                  Campo
                                  requerido</div>
                              <div class="invalid-feedback"
                                  ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                  Escribe
                                  solo letras</div> -->
                            </div>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="puesto" *ngIf="lang == 'ES'">Nivel organizacional*</label>
                                <label class="my-2 fc-secondary" for="puesto" *ngIf="lang == 'PT'">Nível organizacional*</label>
                                <label class="my-2 fc-secondary" for="puesto" *ngIf="lang == 'EN'">Organizational level*</label>
                                <select class="form-select form-select-lg"
                                        aria-label="form-select-sm example"
                                        id="puesto"
                                        name="puesto"
                                        formControlName="puesto"
                                        autocomplete="off"
                                        required>
                                        <option value="" selected disabled hidden *ngIf="lang == 'ES'">Selecciona una opción</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'PT'">Selecione uma opção</option>
                                        <option value="" selected disabled hidden *ngIf="lang == 'EN'">Select an option</option>
                                        <option [ngValue]="i" *ngFor="let i of inputOptionsLevelOrganizatinal">{{i}}</option>
                                </select>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                          <div class="col m-2">
                            <label class="my-2 fc-secondary" for="tipoMoneda">{{select_coin.label}}</label>
                            <select class="form-select form-select-lg"
                                    aria-label="form-select-sm example"
                                    id="tipoMoneda"
                                    name="tipoMoneda"
                                    formControlName="tipoMoneda"
                                    autocomplete="off"
                                    [(ngModel)]="currency"
                                    (change)="setCurrency(lang, currency);"
                                    required>
                              <option value="" selected disabled hidden>{{select_coin.option}}</option>
                              <option [ngValue]="i.id" *ngFor="let i of select_coin.coin">{{i.text}}</option>
                            </select>
                          </div>
                            <div class="col m-2">
                                <label class="my-2 fc-secondary" for="ingreso">{{select_income.label}}</label>
                                <select class="form-select form-select-lg"
                                        aria-label="form-select-sm example"
                                        id="ingreso"
                                        name="ingreso"
                                        formControlName="ingreso"
                                        autocomplete="off"
                                        required>
                                        <option value="" selected disabled hidden>{{select_income.option}}</option>
                                        <option [ngValue]="i" *ngFor="let i of select_income.income">{{i}}</option>
                                </select>
                                <!-- <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.required && SIGNUP.formUserSignup.nombre.$touched">
                                    Campo
                                    requerido</div>
                                <div class="invalid-feedback"
                                    ng-show="SIGNUP.formUserSignup.nombre.$error.pattern && SIGNUP.formUserSignup.nombre.$touched">
                                    Escribe
                                    solo letras</div> -->
                            </div>
                            <div class="col m-2">
                                <div class="form-check my-4">
                                    <input class="form-check-input"
                                            type="checkbox"
                                            id="terminos"
                                            formControlName="terminos"
                                            required>
                                    <label class="form-check-label" for="terminos" *ngIf="lang == 'ES'">
                                        He leído y acepto el <a href="https://fs.hubspotusercontent00.net/hubfs/4024454/Plataforma%20Lite/Aviso%20de%20P/Aviso%20de%20Privacidad-1.pdf" target="_blank">aviso de privacidad</a>
                                    </label>
                                    <label class="form-check-label fc-secondary" for="terminos" *ngIf="lang == 'PT'">
                                        Li e aceito o <a href="https://fs.hubspotusercontent00.net/hubfs/4024454/Plataforma%20Lite/Aviso%20de%20P/Aviso%20de%20Privacidad-1.pdf" target="_blank">aviso de privacidade</a>
                                    </label>
                                    <label class="form-check-label fc-secondary" for="terminos" *ngIf="lang == 'EN'">
                                        I have read and accept the <a href="https://fs.hubspotusercontent00.net/hubfs/4024454/Plataforma%20Lite/Aviso%20de%20P/Aviso%20de%20Privacidad-1.pdf" target="_blank">privacy notice</a>
                                    </label>
                                </div>
                            </div>
                            <div class="col text-end m-2">
                                <div class="row">
                                    <div class="col-4 mx-auto">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn text-white bg-primary-blue btn-home-start" (click)="sendPerdonalData()"
                                            [disabled]="!formPersonalData.valid">
                                                <span *ngIf="lang == 'ES'">Comenzar</span>
                                                <span *ngIf="lang == 'PT'">Começar</span>
                                                <span *ngIf="lang == 'EN'">Start</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>
