import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';

import { FwService } from '@services/fw.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  typeSelected: string;

  formPersonalData: FormGroup;
  faGlobe = faGlobe;
  idCompany='';
  company = '';
  idGroup = '';
  group = '';
  version = '';
  partner = '';
  countries = [];

  lang = '';
  currency = '';

  genero = ['Masculino', 'Femenino', 'Prefiero no decirlo'];
  generoPT = ['Masculino', 'Feminino', 'Prefiro não dizer'];
  generoEN = ['Male', 'Female', 'I prefer not to say'];
  inputOptionsGenero = [];

  filialAlfa = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialAlfaPT = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialAlfaEN = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialIngram = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialIngramPT = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialIngramEN = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialGrupoParker = ['COR Mty', 'Parker FC Toluca y Mty', 'Parker Motion and Control', 'Parker Hidraulica', 'Parker Matamoros I', 'Parker Matamoros II', '320 - Parker Tijuana', 'Parker Legris', 'MEX GPD Planta Monterrey Hyd', 'Parker Chomerics Mty'];
  filialNatura = [ 'Avon', 'Natura', 'The Body Shop', 'NAtura & Co' ];
  filialHeineken = [ 'Confianza', 'Sindicalizado'];
  inputOptionsFilial = [];

  levelOrganizatinal = ['Director', 'Gerente', 'Jefatura, Analista, Administrativo', 'Operativo'];
  levelOrganizatinalPT = ['Diretor', 'Gerente', 'Chefe, Analista, Administrativo', 'Operacional'];
  levelOrganizatinalEN = ['Director', 'Manager', 'Leadership, Analyst, Administrative', 'Operative'];
  levelOrganizatinalNatura = [ 'Analista', 'Coordinador', 'Gerente', 'Director', 'Fuerza de Ventas', 'Equipo de Tienda' ];
  inputOptionsLevelOrganizatinal = [];

  /*income = ['Menor a $20,000', '$20,000 a $50,000', '$50,000 a $100,000', '$100,000 a $150,000', 'Mayor a $150,000'];
  incomePT = ['Menos de $20,000', '$20,000 a $50,000', '$50,000 a $100,000', '$100,000 a $150,000', 'Acima de $150,000'];
  incomeEN = ['Less than $20,000', '$20,000 to $50,000', '$50,000 to $100,000', '$100,000 to $150,000', 'Greater than $150,000'];
  inputOptionsIncome = [];*/

  language_es = [
    { id: 'ES', text: 'Español' },
    { id: 'PT', text: 'Portugués' },
    { id: 'EN', text: 'Inglés' },
  ];

  language_pt = [
    { id: 'ES', text: 'Espanhol' },
    { id: 'PT', text: 'Português' },
    { id: 'EN', text: 'Inglês' },
  ];

  language_en = [
    { id: 'ES', text: 'Spanish' },
    { id: 'PT', text: 'Portuguese' },
    { id: 'EN', text: 'English' },
  ];

  cfg_coins = [
    { lang: 'ES', label: 'Tipo moneda*', option: 'Selecciona una opción',
      coin: [ { id: 'MXN', text: 'Peso mexicano', simbolo: '$' }, { id: 'USD', text: 'Dólar estadounidense', simbolo: 'US$' }, { id: 'EUR', text: 'Euro', simbolo: '€' }, { id: 'BRL', text: 'Real brasileño', simbolo: 'R$' } ] },
    { lang: 'PT', label: 'Tipo de moeda*', option: 'Selecione uma opção',
      coin: [ { id: 'MXN', text: 'Peso mexicano', simbolo: '$' }, { id: 'USD', text: 'USD', simbolo: 'US$' }, { id: 'EUR', text: 'Euro', simbolo: '€' }, { id: 'BRL', text: 'Real brasileiro', simbolo: 'R$' } ] },
    { lang: 'EN', label: 'Coin type', option: 'Select an option',
      coin: [ { id: 'MXN', text: 'Mexican peso', simbolo: '$' }, { id: 'USD', text: 'American dollar', simbolo: 'US$' }, { id: 'EUR', text: 'Euro', simbolo: '€' }, { id: 'BRL', text: 'Brazilian real', simbolo: 'R$' } ] }
  ];

  cfg_income = [
    { id: 1, incomes: [
          { lang: 'ES', label: 'Rango de ingreso mensual*', option: 'Selecciona una opción',
            income: ['Menor a {S}20,000', '{S}20,000 a {S}50,000', '{S}50,000 a {S}100,000', '{S}100,000 a {S}150,000', 'Mayor a {S}150,000'] },
          { lang: 'PT', label: 'Rango de ingreso mensual*', option: 'Selecione uma opção',
            income: ['Menos de {S}20,000', '{S}20,000 a {S}50,000', '{S}50,000 a {S}100,000', '{S}100,000 a {S}150,000', 'Acima de {S}150,000'] },
          { lang: 'EN', label: 'Monthly range*', option: 'Select an option',
            income: ['Less than {S}20,000', '{S}20,000 to {S}50,000', '{S}50,000 to {S}100,000', '{S}100,000 to {S}150,000', 'Greater than {S}150,000'] }
    ] },
    { id: 2, incomes: [
          { lang: 'ES', label: 'Rango de ingreso mensual*', option: 'Selecciona una opción',
            income: ['Menor a {S}2,500', '{S}2,500 a {S}7,000', '{S}7,000 a {S}12,000', 'Mayor a {S}12,000'] },
          { lang: 'PT', label: 'Rango de ingreso mensual*', option: 'Selecione uma opção',
            income: ['Menos de {S}2,500', '{S}2,500 a {S}7,000', '{S}7,000 a {S}12,000', 'Acima de {S}12,000'] },
          { lang: 'EN', label: 'Monthly range*', option: 'Select an option',
            income: ['Less than {S}2,500', '{S}2,500 a {S}7,000', '{S}7,000 a {S}12,000', 'Greater than {S}12,000'] }
    ] },
    { id: 3, incomes: [
          { lang: 'ES', label: 'Rango de ingreso mensual*', option: 'Selecciona una opción',
            income: ['Menor a {S}2,000', '{S}2,000 a {S}4,000', '{S}4,000 a {S}8,000', 'Mayor a {S}8,000'] },
          { lang: 'PT', label: 'Rango de ingreso mensual*', option: 'Selecione uma opção',
            income: ['Menos de {S}2,000', '{S}2,000 a {S}4,000', '{S}4,000 a {S}8,000', 'Acima de {S}8,000'] },
          { lang: 'EN', label: 'Monthly range*', option: 'Select an option',
            income: ['Less than {S}2,000', '{S}2,000 a {S}4,000', '{S}4,000 a {S}8,000', 'Greater than {S}8,000'] }
    ] }
  ];

  select_coin = {
    label: null,
    option: null,
    coin: null
  };

  select_income = {
      label: null,
      option: null,
      income: null
    };

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private fwServices: FwService,
              private spinnerService: NgxSpinnerService ) {
    this.typeSelected = 'ball-fussion';
    this.formPersonalData = this.fb.group({
      'mail': ['', Validators.compose([
                Validators.required,
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
              ])],
      'nacimiento': ['', Validators.compose([
                Validators.required
              ])],
      'countryCode': ['', Validators.compose([
                Validators.required
              ])],
      'sexo': ['', Validators.compose([
                Validators.required
              ])],
      'filial': [''],
      'puesto': ['', Validators.compose([
                Validators.required
              ])],
      'ingreso': ['', Validators.compose([
                Validators.required
              ])],
      'tipoMoneda': ['', Validators.compose([
                      Validators.required
                    ])],
      'terminos': [false, Validators.compose([
                  Validators.required
                ])]
    })

    if((this.company == 'Alfa Corporativo' && this.group == 'Estudio FW') || this.company == 'Ingram' || this.company == 'Grupo Parker') {
      this.formPersonalData.get('filial').setValidators(Validators.required);
    }
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.idGroup = params.idGroup;
        this.group = params.group;
        this.idCompany = params.idCompany;
        this.company = params.company;
        this.version = params.version;
        this.lang = params.lang ? params.lang : 'ES' ;
        this.partner = params.partner ? params.partner : '';
      }
    );
    this.countries = [
      {
          "nameEs": "Antigua & Barbuda",
          "nameEn": "Antigua And Barbuda",
          "code": "AG"
      },
      {
          "nameEs": "Aruba",
          "nameEn": "Aruba",
          "code": "AW"
      },
      {
          "nameEs": "Bahamas",
          "nameEn": "Bahamas",
          "code": "BS"
      },
      {
          "nameEs": "Barbados",
          "nameEn": "Barbados",
          "code": "BB"
      },
      {
          "nameEs": "Bolivia",
          "nameEn": "Bolivia",
          "code": "BO"
      },
      {
          "nameEs": "Brasil",
          "nameEn": "Brazil",
          "code": "BR"
      },
      {
          "nameEs": "Chile",
          "nameEn": "Chile",
          "code": "CL"
      },
      {
          "nameEs": "Colombia",
          "nameEn": "Colombia",
          "code": "CO"
      },
      {
          "nameEs": "Costa Rica",
          "nameEn": "Costa Rica",
          "code": "CR"
      },
      {
          "nameEs": "Ecuador",
          "nameEn": "Ecuador",
          "code": "EC"
      },
      {
          "nameEs": "El Salvador",
          "nameEn": "El Salvador",
          "code": "SV"
      },
      {
          "nameEs": "Guatemala",
          "nameEn": "Guatemala",
          "code": "GT"
      },
      {
          "nameEs": "Honduras",
          "nameEn": "Honduras",
          "code": "HN"
      },
      {
          "nameEs": "Jamaica",
          "nameEn": "Jamaica",
          "code": "JM"
      },
      {
          "nameEs": "México",
          "nameEn": "Mexico",
          "code": "MX"
      },
      {
          "nameEs": "Nicaragua",
          "nameEn": "Nicaragua",
          "code": "NI"
      },
      {
          "nameEs": "Panamá",
          "nameEn": "Panama",
          "code": "PA"
      },
      {
          "nameEs": "Paraguay",
          "nameEn": "Paraguay",
          "code": "PY"
      },
      {
          "nameEs": "Perú",
          "nameEn": "Peru",
          "code": "PE"
      },
      {
          "nameEs": "Puerto Rico",
          "nameEn": "Puerto Rico",
          "code": "PR"
      },
      {
          "nameEs": "República Dominicana",
          "nameEn": "Dominican Republic",
          "code": "DO"
      },
      {
          "nameEs": "Saint Lucia",
          "nameEn": "Saint Lucia",
          "code": "LC"
      },
      {
          "nameEs": "Trinidad & Tobago",
          "nameEn": "Trinidad And Tobago",
          "code": "TT"
      },
      {
          "nameEs": "Uruguay",
          "nameEn": "Uruguay",
          "code": "UY"
      },
      {
          "nameEs": "Venezuela",
          "nameEn": "Venezuela",
          "code": "VE"
      }
    ];
    this.inputOptionsGenero = this.genero;
    this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfa :
        this.company == 'Ingram' ? this.filialIngram :
        this.company == 'Grupo Parker' ? this.filialGrupoParker :
        this.company == 'Natura' ? this.filialNatura :
        this.company == 'Heineken' ? this.filialHeineken : [];

    this.inputOptionsLevelOrganizatinal = this.company == 'Natura' ? this.levelOrganizatinalNatura : this.levelOrganizatinal;
    //this.inputOptionsIncome = this.income;
    this.setCoin( this.lang );
    this.fromView();
  }

  fromView(){
    if( localStorage.getItem('fromView')){
      let _personalData = JSON.parse(localStorage.getItem('personalData'));
      this.setLanguage(_personalData.idioma);
      this.currency = _personalData.tipoMoneda;
      this.setCurrency( this.lang, this.currency );
      this.formPersonalData.controls['mail'].setValue(_personalData.correo);
      this.formPersonalData.controls['nacimiento'].setValue(_personalData.fechaNacimiento);
      this.formPersonalData.controls['countryCode'].setValue(_personalData.countryCode);
      this.formPersonalData.controls['sexo'].setValue(_personalData.sexo);
      this.formPersonalData.controls['filial'].setValue(_personalData.filial);
      this.formPersonalData.controls['puesto'].setValue(_personalData.puesto);
      this.formPersonalData.controls['ingreso'].setValue(_personalData.rangoIngresos);
      this.formPersonalData.controls['terminos'].setValue(_personalData.terminos);
    } else {
      localStorage.clear();
    }
  }

  sendPerdonalData(){
    let _pais = this.countries.find(el => el.code == this.formPersonalData.controls['countryCode'].value);
    let _personalData = {
      correo: this.formPersonalData.controls['mail'].value,
      idEmpresa: this.idCompany,
      empresa: this.company,
      fechaNacimiento: this.formPersonalData.controls['nacimiento'].value,
      countryCode: this.formPersonalData.controls['countryCode'].value,
      filial: this.formPersonalData.controls['filial'].value,
      idGrupo:this.idGroup,
      grupo: this.group,
      puesto: this.formPersonalData.controls['puesto'].value,
      rangoIngresos: this.formPersonalData.controls['ingreso'].value,
      sexo: this.formPersonalData.controls['sexo'].value,
      terminos: this.formPersonalData.controls['terminos'].value,
      version: this.version,
      idioma: this.lang,
      pais: _pais ? _pais.nameEs : '',
      tipoMoneda: this.currency
    }
    localStorage.setItem( 'personalData', JSON.stringify(_personalData));
    this.router.navigate(['/questions']);
  }

  resetForm(){
    this.formPersonalData.reset({
      mail: '',
      nacimiento: '',
      countryCode: '',
      filial: '',
      ingreso: '',
      //tipoMoneda: '',
      sexo: '',
      puesto: '',
      terminos: false
    });
  }

  setLanguage( lang ){
    switch (lang) {
      case 'ES':
        this.lang = 'ES';
        this.inputOptionsGenero = this.genero;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfa : this.company == 'Ingram' ? this.filialIngram : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinal;
        //this.inputOptionsIncome = this.income;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
            this.setCoin( this.lang );
          }, 20);
        }
        break;
      case 'PT':
        this.lang = 'PT';
        this.inputOptionsGenero = this.generoPT;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfaPT : this.company == 'Ingram' ? this.filialIngramPT : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinalPT;
        //this.inputOptionsIncome = this.incomePT;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
            this.setCoin( this.lang );
          }, 20);
        }
        break;
      case 'EN':
        this.lang = 'EN';
        this.inputOptionsGenero = this.generoEN;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfaEN : this.company == 'Ingram' ? this.filialIngramEN : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinalEN;
        //this.inputOptionsIncome = this.incomeEN;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
            this.setCoin( this.lang );
          }, 20);
        }
        break;
      default:
        break;
    }
  }

  setCoin( lang ) {
    this.cfg_coins.forEach( cfgCoin => {
      if( cfgCoin.lang === lang ) {
        this.select_coin.label = cfgCoin.label;
        this.select_coin.option = cfgCoin.option;
        this.select_coin.coin = cfgCoin.coin;
      }
    } );

    this.currency = 'MXN';
    this.setCurrency( lang, this.currency );
  }

  setCurrency( lang, currency ) {
    this.spinnerService.show();
    let _id;
    let _sim;

    // logica de negocio para seleccionar rango de ingresos dependiendo de la moneda
    switch ( currency ) {
      case 'MXN':
        _id = 1;
      break;
      case 'BRL':
        _id = 2;
      break;
      case 'USD': case 'EUR':
        _id = 3;
      break;
    }

    // Obtenemos simbolo
    this.select_coin.coin.forEach( el => {
       if( el.id === currency ) {
         _sim = el.simbolo;
       }
     } );

     // Obtenemos lista de ingresos en base a la configuración
     this.cfg_income.forEach( el => {
       if( el.id === _id ) {
         el.incomes.forEach( elIncome => {
            if( elIncome.lang === lang ) {
              this.select_income.label = elIncome.label;
              this.select_income.option = elIncome.option;
              this.select_income.income = elIncome.income.map( e => {
                let resultArr = e.split( '{S}' );
                return resultArr.join( _sim );
              });
            }
         });
       }
     } );

     this.formPersonalData.controls['ingreso'].setValue( '' );
     this.spinnerService.hide();
  }
}
