import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api_constants } from '@constants/api-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FwService {

  constructor(private http: HttpClient) { }

  getCountries(params): Observable<any>{
    let _params = {
      params: {
        company: params
      }
    }
    return this.http.get<any>( api_constants.FW.GET_COUNTRIES, _params );
  }

  getFw(idioma, countryCode): Observable<any>{
    let _params = {
      params: {
        idioma: idioma,
        countryCode: countryCode
      }
    }
    return this.http.get<any>( api_constants.FW.GET_FW, _params );
  }

  saveFW(_params): Observable<any>{
    return this.http.post<any>( api_constants.FW.SAVE_FW, _params );
  }
}
